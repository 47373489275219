var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          getByIdOnEditFilter: function () {
            return false
          },
          isDataFromModel: false,
          isAddEditSidbar: false,
          copyData: _vm.selectedRow,
          sidebarData: _vm.selectedProcedure,
          addEditLink: {
            path: "/proc-codes/update-procedures-codes",
            name: "proc-codes-update",
            params: { id: _vm.selectedRow.id },
          },
          fields: _vm.fields,
          itemsRepo: _vm.procedures,
        },
        on: {
          "update:copyData": function ($event) {
            _vm.selectedRow = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedRow = $event
          },
          "update:sidebarData": function ($event) {
            _vm.selectedProcedure = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedProcedure = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "Add",
            fn: function () {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      type: "button",
                      variant: "outline-primary",
                      size: "sm",
                      pill: "",
                    },
                  },
                  [_c("feather-icon", { attrs: { icon: "UploadIcon" } })],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      type: "button",
                      variant: "outline-primary",
                      size: "sm",
                      pill: "",
                    },
                  },
                  [_c("feather-icon", { attrs: { icon: "DownloadIcon" } })],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      type: "button",
                      variant: "outline-primary",
                      size: "sm",
                      pill: "",
                    },
                  },
                  [_c("feather-icon", { attrs: { icon: "ColumnsIcon" } })],
                  1
                ),
                _c(
                  "b-dropdown",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(255, 255, 255, 0.15)",
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: { 400: true },
                      },
                    ],
                    staticClass: "dropdown-icon-wrapper",
                    attrs: {
                      id: "dropdown-grouped",
                      text: "Actions",
                      size: "sm",
                      right: "",
                      variant: "primary",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "mr-1" }, [
                              _vm._v("Actions"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      [
                        _c(
                          "b-link",
                          {
                            staticClass: "text-body",
                            attrs: { to: "/proc-codes/add-procedures-codes" },
                          },
                          [_vm._v("Add Procedure Code\n          ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.onRowSelected({}, "copy")
                          },
                        },
                      },
                      [_vm._v("Copy Settings Between Codes")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(action-right)",
            fn: function (data) {
              return [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(40, 199, 111, 0.15)",
                        expression: "'rgba(40, 199, 111, 0.15)'",
                        modifiers: { 400: true },
                      },
                    ],
                    staticClass: "mr-1 d-flex px-0",
                    attrs: { variant: "flat-primary", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.onRowSelected(data, "clone")
                      },
                    },
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "CopyIcon", size: "16" },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "validation-observer",
        { ref: "simpleRulesCopy" },
        [
          _c(
            "b-form",
            [
              _c(
                "b-sidebar",
                {
                  attrs: {
                    id: "sidebar-right",
                    "bg-variant": "white",
                    "no-header": "",
                    right: "",
                    backdrop: "",
                    shadow: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "b-container",
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _vm.mode == "copy"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-100 text-left pl-1 pb-2",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "danger-text" },
                                              [_vm._v("Note:")]
                                            ),
                                            _vm._v(
                                              " copying settings to a\n                  new destination overrides the existing settings in the\n                  destination.\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "w-100 text-right pb-2" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            staticClass: "mr-1",
                                            attrs: {
                                              variant: "outline-primary",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.sidebarCopy = false
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Cancel\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: {
                                              variant: "primary",
                                              size: "sm",
                                              type: "submit",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.validationFormCopy.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "mr-50",
                                              attrs: { icon: "CheckIcon" },
                                            }),
                                            _vm.mode == "copy"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "align-middle",
                                                  },
                                                  [_vm._v("Copy")]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass: "align-middle",
                                                  },
                                                  [_vm._v("Clone")]
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.sidebarCopy,
                    callback: function ($$v) {
                      _vm.sidebarCopy = $$v
                    },
                    expression: "sidebarCopy",
                  },
                },
                [
                  _c(
                    "b-container",
                    { staticClass: "px-2 mt-1" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _vm.mode == "copy"
                                ? _c("h5", [
                                    _vm._v("Copy Procedure Code Settings"),
                                  ])
                                : _vm._e(),
                              _vm.mode == "clone"
                                ? _c("h5", [_vm._v("Clone Procedure Code")])
                                : _vm._e(),
                              _c("label", [
                                _vm._v(
                                  "\n                Select the settings you want to be duplicated to the new\n                product code\n              "
                                ),
                              ]),
                              _vm.mode == "clone"
                                ? _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { label: "Procedure Name" },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Procedure Name",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedRowCopy
                                                          .copyToName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.selectedRowCopy,
                                                          "copyToName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedRowCopy.copyToName",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1356682376
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    label:
                                      _vm.mode == "clone"
                                        ? "Procedure Code"
                                        : "Copy settings from",
                                  },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Procedure Code",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm.mode == "copy"
                                              ? _c("infinit-select", {
                                                  attrs: {
                                                    placeholder: "",
                                                    repo: _vm.procedures,
                                                    label: "procCode",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedRowCopy
                                                        .copyToId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.selectedRowCopy,
                                                        "copyToId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedRowCopy.copyToId",
                                                  },
                                                })
                                              : _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: "",
                                                    disabled:
                                                      _vm.mode == "clone",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedRowCopy
                                                        .selectedProc.procCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.selectedRowCopy
                                                          .selectedProc,
                                                        "procCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedRowCopy.selectedProc.procCode",
                                                  },
                                                }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                { attrs: { label: "" } },
                                [
                                  _c("b-form-checkbox-group", {
                                    staticClass: "demo-inline-spacing",
                                    attrs: {
                                      id: "checkbox-group-1",
                                      options: _vm.copyOptions,
                                      "text-field": "text",
                                      name: "flavour-1",
                                    },
                                    model: {
                                      value: _vm.selectedRowCopy.items,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedRowCopy,
                                          "items",
                                          $$v
                                        )
                                      },
                                      expression: "selectedRowCopy.items",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.mode == "copy"
                                ? _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        label: "To these procedure code(s)",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "CopyTo",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      placeholder: "",
                                                      repo: _vm.procedures,
                                                      label: "procCode",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedRowCopy
                                                          .selectedProc.id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.selectedRowCopy
                                                            .selectedProc,
                                                          "id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedRowCopy.selectedProc.id",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2132619450
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }